exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._2e_WWb6otXjLxNtC-Zz74n ._2TE87GVH0AINCbMyR5j_uW{color:#333 !important;position:absolute !important;top:1em;z-index:990;padding:2rem !important;background:#fff !important;border:1px solid #dadada !important;font-size:14px;left:0;right:0}._2e_WWb6otXjLxNtC-Zz74n ._2TE87GVH0AINCbMyR5j_uW ._16UMZcmNbn1O2Zc8kOu-n2{display:inline-block;position:absolute;right:2.4375rem;top:2rem}._2e_WWb6otXjLxNtC-Zz74n ._2TE87GVH0AINCbMyR5j_uW ._16UMZcmNbn1O2Zc8kOu-n2 ._3KPPjqDS9J2nzsxgcIGqT2{font-size:2.25rem;color:#8a8a8a;cursor:pointer}._2e_WWb6otXjLxNtC-Zz74n ._2TE87GVH0AINCbMyR5j_uW ._16UMZcmNbn1O2Zc8kOu-n2 ._3KPPjqDS9J2nzsxgcIGqT2:hover{color:#0a0a0a}._2e_WWb6otXjLxNtC-Zz74n ._2TE87GVH0AINCbMyR5j_uW>div{margin-bottom:.3rem}._2e_WWb6otXjLxNtC-Zz74n ._2TE87GVH0AINCbMyR5j_uW>div._1BhqplU-Q71UvN84WLp_zy{color:#666;font-size:13px}._2e_WWb6otXjLxNtC-Zz74n ._2TE87GVH0AINCbMyR5j_uW h3{color:#1779ba;font-weight:bold;padding-right:.9375rem}@media only screen and (min-width: 49.30769em){._2e_WWb6otXjLxNtC-Zz74n ._2TE87GVH0AINCbMyR5j_uW{left:3rem;right:3rem}}._1u8ydVYkr3MVJlZUNP2sJ0{position:absolute;top:0;background:rgba(0,0,0,.7);bottom:0;left:0;right:0;z-index:900;padding:3rem}", ""]);

// Exports
exports.locals = {
	"single_event": "_2e_WWb6otXjLxNtC-Zz74n",
	"single_event_inner": "_2TE87GVH0AINCbMyR5j_uW",
	"close": "_16UMZcmNbn1O2Zc8kOu-n2",
	"dashicons": "_3KPPjqDS9J2nzsxgcIGqT2",
	"event_dates": "_1BhqplU-Q71UvN84WLp_zy",
	"backdrop": "_1u8ydVYkr3MVJlZUNP2sJ0"
};